var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container joinInterests" },
    [
      _c("page-header", {
        attrs: { title: "관심 분야를 선택해주세요.", "title-size": "3.2rem" }
      }),
      _c("div", {
        staticClass: "ui-border-line ui-mt-4 ui-mb-4",
        staticStyle: { height: "10px" }
      }),
      _c(
        "tag-box",
        _vm._l(_vm.genreArr, function(row, key) {
          return _c(
            "li",
            {
              key: key,
              class: {
                on:
                  _vm.formData.genre.split(",").indexOf(row) !== -1 ||
                  ("직접 입력" === row && !_vm.formData.genreEtc === false)
              },
              on: {
                click: function() {
                  if (row === "직접 입력") {
                    _vm.$refs.genreEtcModal.show()
                  } else {
                    var arr = _vm.formData.genre.split(",") || []
                    var index = arr.indexOf(row)
                    if (index === -1) {
                      arr.push(row)
                    } else {
                      arr.splice(index, 1)
                    }
                    _vm.formData.genre = arr
                      .filter(function(row) {
                        return !!row
                      })
                      .join(",")
                  }
                }
              }
            },
            [_vm._v(" " + _vm._s(row) + " ")]
          )
        }),
        0
      ),
      _c(
        "modal",
        {
          ref: "genreEtcModal",
          staticClass: "etc",
          attrs: {
            title: "직접 입력해주세요",
            showCb: function() {
              return (_vm.modalData.genreEtc = _vm.formData.genreEtc)
            },
            confirm: "등록",
            confirmCb: function(modal) {
              _vm.formData.genreEtc = _vm.modalData.genreEtc
              modal.hide()
            }
          }
        },
        [
          _c("div", { attrs: { slot: "body" }, slot: "body" }, [
            _c("div", { staticClass: "ui-inputbox etc" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.modalData.genreEtc,
                    expression: "modalData.genreEtc"
                  }
                ],
                attrs: { type: "text" },
                domProps: { value: _vm.modalData.genreEtc },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.modalData, "genreEtc", $event.target.value)
                  }
                }
              })
            ])
          ])
        ]
      ),
      _c("footer-box", {
        attrs: {
          submitText: "다음",
          submitCb: function() {
            return _vm.nextStep()
          },
          submitDisabled: !_vm.validate
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }