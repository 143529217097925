<template>
    <div class="container joinInterests">
        <page-header
            :title="`관심 분야를 선택해주세요.`"
            title-size="3.2rem"
        />

        <div class="ui-border-line ui-mt-4 ui-mb-4" style="height: 10px"></div>

        <tag-box>
            <li
                v-for="(row, key) in genreArr"
                :key="key"
                @click="() => {
                    if (row === '직접 입력') {
                        $refs.genreEtcModal.show()
                    } else {
                        let arr = formData.genre.split(',') || []
                        let index = arr.indexOf(row)
                        if (index === -1) {
                            arr.push(row)
                        } else {
                            arr.splice(index, 1)
                        }
                        formData.genre = arr.filter(row => !!row).join(',')
                    }
                }"
                :class="{ on: formData.genre.split(',').indexOf(row) !== -1 || ('직접 입력' === row && !formData.genreEtc === false)}"
                >
                {{row}}
            </li>
        </tag-box>

        <modal
            ref="genreEtcModal"
            title="직접 입력해주세요"
            class="etc"
            :showCb="() => modalData.genreEtc = formData.genreEtc"
            confirm="등록"
            :confirmCb="modal => {
                formData.genreEtc = modalData.genreEtc
                modal.hide()
            }"
        >
            <div slot="body">
                <div class="ui-inputbox etc">
                    <input type="text" v-model="modalData.genreEtc">
                </div>
            </div>
        </modal>

        <footer-box
            submitText="다음"
            :submitCb="() => nextStep()"
            :submitDisabled="!validate"
        >
        </footer-box>
    </div>
</template>

<script>
import PageHeader from '@/components/common/PageHeader'
import FooterBox from '@/components/common/FooterBox'
import Modal from '@/components/common/Modal'
import TagBox from '@/components/common/TagBox'

export default {
    name: 'JoinInterests',
    components: {
        PageHeader,
        FooterBox,
        Modal,
        TagBox,
    },
    data() {
        return {
            genreArr: [
                '결혼식',
                '주례없는 결혼식',
                '회갑/칠순',
                '돌잔치',
                '체육대회',
                '기업행사',
                '지역/학교축제',
                '홍보/전시행사',
                '정기 공연/연주',
                '모임행사',
                '레슨/클래스',
                '모델 섭외',
                '직접 입력',
            ],
            formData: {
                genre: '',
                genreEtc: '',
            },
            modalData: {
                genreEtc: '',
            },
        }
    },
    computed: {
        validate() {
            return !this.formData.genre === false
        },
    },
    beforeRouteEnter (to, from, next) {
        next(Vue => {
            if (!sessionStorage.getItem('joinData')) return
            try {
                let joinData = JSON.parse(sessionStorage.getItem('joinData'))
                if (!joinData.interests) return

                let arr = joinData.interests.split(',')

                let etcIndex = arr.findIndex(row => {
                    return Vue.genreArr.indexOf(row) === -1
                })

                if (etcIndex !== -1) {
                    Vue.formData.genreEtc = arr[etcIndex]
                }

                Vue.formData.genre = joinData.interests
            } catch (error) {
                //
            }
        })
    },
    methods: {
        nextStep() {
            try {
                let interests = ''
                let joinData = JSON.parse(sessionStorage.getItem('joinData'))
                if (this.formData.genre) {
                    if (interests) interests += ','
                    interests += this.formData.genre
                }
                if (this.formData.genreEtc) {
                    if (interests) interests += ','
                    interests += this.formData.genreEtc
                }
                joinData.interests = interests
                sessionStorage.setItem('joinData', JSON.stringify(joinData))
            } catch (error) {
                //
            }

            if (this.formData.genre) {
                // const arr: [] = this.formData.genre.split(',') || []
                // const isWeddingOffer = arr.every((genre) => {
                //     return ['결혼식', '주례없는 결혼식'].indexOf(genre) !== -1
                // })

                // if (isWeddingOffer) {
                //     this.$router.push('/join/wedding-date')
                // } else {
                this.$router.push('/join/input')
                //}
            }
        },
    },
}
</script>

<style>

</style>